import "leaflet/dist/leaflet.css";

import L from "leaflet";

import marker from "../images/location-marker.png";

document.addEventListener("DOMContentLoaded", () => {
  const locations = document.querySelectorAll(".location");

  // check that locations are present
  if (locations.length <= 0) {
    return;
  }

  // Icon config
  const icon = L.icon({
    iconUrl: marker,
    iconSize: [46, 46],
    iconAnchor: [23, 23],
    popupAnchor: [0, -23]
  });

  locations.forEach(location => {
    const data = location.dataset;

    let map = L.map(location, {
      center: [data.lat, data.lng],
      zoom: 13,
      tap: false,
      scrollWheelZoom: false
    });
    L.tileLayer("https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      maxZoom: 18
    }).addTo(map);
    let marker = L.marker([data.lat, data.lng], { icon: icon }).addTo(map);
    map.on("popupopen", function(e) {
      let px = map.project(e.popup._latlng);
      px.y -= e.popup._container.clientHeight / 2;
      map.panTo(map.unproject(px), { animate: true });
    });
    marker
      .bindPopup(
        `
          <div class="h4">${data.title}</div>
          <dl>
            ${ data.address ? ` <dt>Address</dt> <dd>${data.address}</dd> ` : "" }
            ${ data.phone ? ` <dt>Phone</dt> <dd>${data.phone}</dd> ` : "" }
            ${ data.email ? ` <dt>Email</dt> <dd>${data.email}</dd> ` : "" }
          </dl>
        `
      )
      .openPopup();
  });
});
